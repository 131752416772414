@import "variables.scss";

body {
  // Prevents the scrollbar from pushing the content sideways every time.
  overflow-y: overlay;
}

#root {
  min-height: 100%;
}

.table {
  display: table;
}

.tr {
  display: table-row;
}

.td {
  display: table-cell;
}

.vertMiddle {
  vertical-align: middle;
}

.vertBottom {
  vertical-align: bottom;
}

.fullWidth {
  width: 100%;
}

.fullheight {
  height: 100%;
}

.full {
  width: 100%;
  height: 100%;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.splitView {
  display: grid;
  grid-template-columns: 50% 50%;
}

.standardWidth,
.extraStandardWidth {
  width: $standardWidth;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.extraStandardWidth {
  width: $extraStandardWidth;
}

.marginTopXSmall {
  margin-top: $xsmall;
}

.marginTopSmall {
  margin-top: $small;
}

.marginTopMediumSmall {
  margin-top: $mediumSmall;
}

.marginTopMedium {
  margin-top: $medium;
}

.marginTopLarge {
  margin-top: $large;
}

.marginTopXLarge {
  margin-top: $xlarge;
}

.marginBottomXSmall {
  margin-bottom: $xsmall;
}

.marginBottomSmall {
  margin-bottom: $small;
}

.marginBottomMediumSmall {
  margin-bottom: $mediumSmall;
}

.marginBottomMedium {
  margin-bottom: $medium;
}

.marginBottomLarge {
  margin-bottom: $large;
}

.marginBottomXLarge {
  margin-bottom: $xlarge;
}

.marginLeftSmall {
  margin-left: $small;
}

.marginLeftMediumSmall {
  margin-left: $mediumSmall;
}

.marginLeftMedium {
  margin-left: $medium;
}

.marginLeftLarge {
  margin-left: $large;
}

.marginLeftXLarge {
  margin-left: $xlarge;
}

.marginRightXSmall {
  margin-right: $xsmall;
}

.marginRightSmall {
  margin-right: $small;
}

.marginRightMediumSmall {
  margin-right: $mediumSmall;
}

.marginRightMedium {
  margin-right: $medium;
}

.marginRightLarge {
  margin-right: $large;
}

.marginRightXLarge {
  margin-right: $xlarge;
}

.sideMargins {
  margin-left: $sideMargin;
  margin-right: $sideMargin;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.centerSection {
  height: 550pt;
  display: flex;
  align-items: center;
}

.centerSection.smallSection {
  height: 340pt;
}

.centerSection.pageSection {
  height: 70vh;
}

.flexBox {
  display: flex;
}

.flexCenter {
  justify-content: center;
}

.flexVerticalCenter {
  align-items: center;
}

.flexGrid {
  display: flex;
  column-gap: 8pt;
  row-gap: 8pt;
  flex-wrap: wrap;
}

// Parent needs to be .relative and specify the height.
.middle {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.dynamicHeaderSpacing {
  margin-top: $medium;
}

.dynamicHeaderSpacingLarge {
  margin-top: $large;
}

.dynamicHeaderSpacingXLarge {
  margin-top: $xlarge;
}

.loadingContainer {
  padding-top: 130pt;
  padding-bottom: 202pt;
}

.loadingContainer img {
  height: 72pt;
}

.separatorLine,
.extraSeparatorLine {
  width: $standardWidth;
  max-width: 100%;
  border-top: 1px solid $veryLightGray;
  margin-left: auto;
  margin-right: auto;
}

.extraSeparatorLine {
  width: $extraStandardWidth;
}

@media screen and (max-width: $extraStandardWidth) {
  .extraSeparatorLine {
    width: auto;
  }

  .standardWidth {
    padding-left: $sideMargin;
    padding-right: $sideMargin;
  }
}

@media screen and (max-width: 500pt) {
  .dynamicHeaderSpacing {
    margin-top: 8pt;
  }

  .dynamicHeaderSpacingLarge {
    margin-top: $medium;
  }

  .dynamicHeaderSpacingXLarge {
    margin-top: $large;
  }
}

@media screen and (max-width: 350pt) {
  .splitView {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
