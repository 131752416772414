@import "variables.scss";

body {
  font-family: $titleFont;
  font-size: $defaultFontSize;
}

h1,
.h1 {
  font-family: $titleFont;
  font-size: 36pt;
  font-weight: 600;
  // letter-spacing: 0.01em;
  color: $blackTextColor;
}

h2,
.h2 {
  font-family: $titleFont;
  font-weight: 600;
  font-size: 24pt;
  color: $blackTextColor;
}

h3,
.h3 {
  font-family: $titleFont;
  font-weight: 600;
  font-size: 18pt;
  color: $blackTextColor;
}

h4,
.h4 {
  font-family: $titleFont;
  font-weight: 600;
  font-size: 14pt;
  color: $blackTextColor;
}

h5,
.h5 {
  font-family: $titleFont;
  font-weight: 500;
  font-size: 14pt;
  letter-spacing: 0.01em;
}

h6 {
  font-family: $titleFont;
  font-weight: 600;
  font-size: 12pt;
}

p {
  font-family: $contentFont;
  line-height: 20pt;
  font-size: 14pt;
}

.label {
  font-size: 16pt;
}

.doubleDefaultFontSize {
  font-size: $defaultFontSize * 2;
}

.sectionTitle {
  font-weight: 500;
}

.sectionSubtitle {
  font-size: 18pt;
  font-weight: 500;
}

.subtitle {
  font-family: $titleFont;
  font-weight: 100;
  font-weight: bold;
  font-size: 18pt;
  letter-spacing: 0.01em;
}

.whiteText {
  color: white;
}

.errorText {
  color: $errorColor;
}

.warningText {
  color: $warningColor;
}

.successText {
  color: $successColor;
}

.discreteText {
  color: $lightGray;
}

.barelyNoticableText {
  color: $veryLightGray;
}

.boldText {
  font-weight: 700;
}

.blueText {
  color: $linkColor;
}

.inTextLink {
  color: $linkColor;
}

.inTextLink:hover {
  color: $textHoverColor;
}

.smallPrint {
  font-size: 8pt;
}

a:hover {
  cursor: pointer;
}

.link {
  color: $linkColor;
}

.link:hover {
  color: $textHoverColor;
}

.link.disabled {
  color: $verySoftTextColor;
}

.mainLink {
  font-weight: 700;
  font-size: $defaultFontSize;
  color: $linkColor;
}

.mainLink:hover {
  color: $textHoverColor;
}

.mainLink.disabled {
  color: $verySoftTextColor;
}

@media screen and (max-width: 500pt) {
  h1,
  .h1 {
    font-size: 32pt;
  }

  .sectionSubtitle {
    font-size: 16pt;
  }
}
