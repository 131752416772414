@import "./variables.scss";

.formContainer {
  border-radius: 2pt;
  border: 1pt solid $veryLightGray;
  text-align: center;
  padding-top: 30pt;
  padding-bottom: 30pt;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 500pt;
  margin-bottom: 40pt;
  box-shadow: $dropShadowLight;
}

.formContainer p {
  width: 400pt;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10pt;
}

.formContainer h1,
.formContainer h2,
.formContainer h3,
.formContainer h4,
.formContainer h5 {
  width: 400pt;
  max-width: 100%;
}

.formMargins {
  width: 500pt;
  max-width: 100%;
  padding-left: $sideMargin;
  padding-right: $sideMargin;
  margin: 0pt;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: $placeholderColor;
  opacity: 1;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: $placeholderColor;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: $placeholderColor;
}

input[type="text"],
input[type="phone"],
input[type="number"],
select,
textarea {
  height: 30pt;
  display: block;
  width: 400pt;
  max-width: 100%;
  margin-top: 16pt;
  border: 0pt;
  border-bottom: 1pt solid black;
  font-family: $titleFont;
  font-size: $defaultFontSize;
}

input.mainFocusInput {
  height: 36pt;
  font-size: $defaultFontSize * 2;
}

textarea {
  height: 45pt;
  resize: none;
  padding-top: 5pt;
  padding-bottom: 5pt;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

select {
  background-color: white;
  border-radius: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.placeholderSelect {
  color: $placeholderColor;
}

// Cleave Styles

* {
  box-sizing: border-box;
}
.inp {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
}
.inp .label {
  position: absolute;
  top: 12px;
  left: 0;
  font-size: 16px;
  color: #9098a9;
  font-weight: 500;
  transform-origin: 0 0;
  transition: all 0.2s ease;
}
.inp .border {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: #07f;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: all 0.15s ease;
}
.inp input {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 12px 0;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #c8ccd4;
  background: none;
  border-radius: 0;
  color: #223254;
  transition: all 0.15s ease;
}
.inp input:hover {
  background: rgba(34, 50, 84, 0.03);
}
.inp input:not(:placeholder-shown) + span {
  color: #5a667f;
  transform: translateY(-26px) scale(0.75);
}
.inp input:focus {
  background: none;
  outline: none;
}
.inp input:focus + span {
  color: #07f;
  transform: translateY(-26px) scale(0.75);
}
.inp input:focus + span + .border {
  transform: scaleX(1);
}

@media only screen and (max-width: 400pt) {
  .formContainer {
    width: 100%;
    border: 0pt;
    margin-bottom: 0pt;
    margin-top: 0pt;
    box-shadow: none;
  }
}
