/// Toolkit colors
@import url("https://use.typekit.net/vzp3zcg.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700&display=swap");

$accentColor: rgba(255, 255, 255, 1); // rgba(255, 173, 148, 0.3);
$backdropFilter: saturate(180%) blur(20px);
$fillBlack: #1a1a1a;
$onBlackTextColor: rgba(255, 255, 255, 0.95);
$accentSelectedColor: $fillBlack;
$accentSelectedTextColor: $onBlackTextColor;
$backgroundColor: #ffffff;
$cardColor: rgba(242, 242, 242, 0.51);
$textColor: #333333;
$blackTextColor: #333333;
$darkModeBackgroundColor: #333333;
$softTextColor: #333333;
$verySoftTextColor: #bdbdbd;
$lightGray: #aaaaaa;
$veryLightGray: #cccccc;
$darkGray: #7a7a7a;
$linkColor: #00b4eb; // #ff8fa1;
$textHoverColor: rgba(255, 98, 49, 1);
$onBlackTextHoverColor: rgba(255, 111, 60, 1);

$badgeColor: #ff4444;

$errorColor: #df2222;
$warningColor: #949400;
$successColor: #008500;
$greenColor: #b4d47f;

$placeholderColor: $lightGray;

$dropShadow: 0px -5px 30px rgba(177, 143, 143, 0.4);

$dropShadowExtraDark: 2px 0px 20px rgba(0, 0, 0, 0.2);

$dropShadowDark: 0px 3px 10px rgba(130, 130, 130, 0.2);
$dropShadowLight: 0px 3px 10px rgba(179, 179, 179, 0.2);

$sunsetGradient: linear-gradient(to bottom right, #fcb574, #ffacb0);
$yellowGreenGradient: radial-gradient(
  circle 807px at 7.7% 17.3%,
  #ffffd1 0%,
  lightblue 92.6%
);

/* Highlight Colors */
$greenHighlight: #b4d47f;
$yellowHighlight: #fff176;
$pinkHighlight: #ffeeed;

/// Toolkit spacing

$xsmall: 4pt;
$small: 8pt;
$mediumSmall: 12pt;
$medium: 16pt;
$large: 24pt;
$xlarge: 40pt;
$sideMargin: 20pt;
$itemSize: 238pt;

$standardWidth: 1000pt;
$extraStandardWidth: 1020pt;

/// Toolkit typography

$titleFont: "futura-pt", sans-serif;
$contentFont: "futura-pt", sans-serif;
$alternativeFont: "Open Sans", sans-serif;

$defaultFontSize: 14pt;
$smallFontSize: 12pt;
$xsmallFontSize: 10pt;
$cardHeight: 275pt;

// Z Index Order

$zindexHidden: -1;
$zindexDefault: 0;
$zindexItemDisplayOverlay: 1;
$zindexUIElements: 1;
$zindexTooltip: 2;

$zindexFiltersSelect: 5;

$zindexMessage: 40;

$zindexHeader: 50;

$zindexSlidePane: 80;

$zindexTabBar: 85;

$zindexModalBackground: 100;
$zindexModalContent: 101;

$zindexConfirmBackground: 150;
$zindexConfirmContent: 151;
