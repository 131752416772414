@import "./variables.scss";

button {
  font-family: $titleFont;
}

button:hover {
  cursor: pointer;
}

.popOutButton {
  background: red;
}

.primaryButton {
  background: none;
  /* green drop shadow 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); */
  border: 1px solid $textColor;
  border-radius: 5px;
  color: black;
  transition: 0.1s;
  text-transform: uppercase;
  font-weight: 600;
}

.primaryButton:disabled {
  border: 1px solid #bbb;
  color: #bbb;
  transition: 0.2s;
}

.primaryButton span,
.primaryButton .whiteLoadingGif {
  display: inline-block;
}

.secondaryButton {
  background: black;
  /* green drop shadow 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); */
  border: 1px solid $textColor;
  border-radius: 5px;
  color: white;
  transition: 0.1s;
  text-transform: uppercase;
  font-weight: 600;
}

.secondaryButton:disabled {
  transition: 0.2s;
}

.secondaryButton span,
.secondaryButton .whiteLoadingGif {
  display: inline-block;
}

.warningButton {
  background: $errorColor;
  /* green drop shadow 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); */
  border: 1px solid $errorColor;
  border-radius: 5px;
  color: white;
  transition: 0.1s;
  text-transform: uppercase;
  font-weight: 600;
}

.warningButton:disabled {
  transition: 0.2s;
}

.warningButton span,
.warningButton .whiteLoadingGif {
  display: inline-block;
}

.normalButton {
  border: 1pt solid $textColor;
  border-radius: 5px;
  color: black;
  text-transform: uppercase;
  font-weight: 600;
  background-color: $backgroundColor;
}

.tinyButton:hover {
  margin: 1pt;
  width: 78pt;
  height: 22pt;
  font-size: 10.2pt;
}

.tinyButton:hover:disabled,
.tinyButton:active:disabled,
.tinyButton:active,
.tinyButton {
  margin: 2pt;
  width: 76pt;
  height: 20pt;
  font-size: 10pt;
  padding: 2pt 6pt;
}

.tinyButton:active,
.tinyButton:focus {
  outline: none;
}

.xsmallButton:hover {
  margin: 1pt;
  width: 62pt;
  height: 32pt;
  font-size: 12.2pt;
}

.xsmallButton:hover:disabled,
.xsmallButton:active:disabled,
.xsmallButton:active,
.xsmallButton {
  margin: 2pt;
  width: 60pt;
  height: 30pt;
  font-size: 12pt;
}

.xsmallButton:active,
.xsmallButton:focus {
  outline: none;
}

.smallButton:hover {
  margin: 1pt;
  width: 152pt;
  height: 32pt;
  font-size: 12.2pt;
  transition: 0.1s;
}

.smallButton:active {
  margin: 3pt;
  width: 148pt;
  height: 28pt;
  font-size: 11.8pt;
  transition: 0.1s;
}

.smallButton:hover:disabled,
.smallButton:active:disabled,
.smallButton {
  margin: 2pt;
  width: 150pt;
  height: 30pt;
  font-size: 12pt;
  cursor: default;
}

.smallButton:active,
.smallButton:focus {
  outline: none;
}

.clickable:hover {
  cursor: pointer;
}

.blackButton {
  background-color: black;
  /* green drop shadow */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  border: 1px solid $textColor;
  border-radius: 5px;
  color: white;
  transition: 0.1s;
  text-transform: uppercase;
  font-weight: bold;
}

.mediumButton:disabled {
  color: #bbb;
  transition: 0.2s;
}

.mediumButton span,
.mediumButton .whiteLoadingGif {
  display: inline-block;
}

.mediumButton:hover {
  margin: 1pt;
  width: 204pt;
  height: 42pt;
  font-size: 12.2pt;
  transition: 0.1s;
}

.mediumButton:active {
  margin: 3pt;
  width: 196pt;
  height: 38pt;
  font-size: 11.8pt;
  transition: 0.1s;
}

.mediumButton:hover:disabled,
.mediumButton:active:disabled,
.mediumButton {
  margin: 2pt;
  width: 200pt;
  height: 40pt;
  font-size: 12pt;
  cursor: default;
}

.mediumButton:active,
.mediumButton:focus {
  outline: none;
}

.mediumButton:disabled {
  color: #bbb;
  transition: 0.2s;
}

.largeButton span,
.largeButton .whiteLoadingGif {
  display: inline-block;
}

.largeButton .whiteLoadingGif {
  margin-top: 2pt;
}

.largeButton:hover {
  margin: 1pt;
  width: 244pt;
  height: 44pt;
  font-size: 12.2pt;
  transition: 0.1s;
}

.largeButton:hover:disabled,
.largeButton:active:disabled,
.largeButton:active,
.largeButton {
  margin: 2pt;
  width: 240pt;
  height: 42pt;
  font-size: 12pt;
  cursor: default;
}

.largeButton:active {
  cursor: pointer;
}

.largeButton:active:disabled {
  cursor: default;
}

.largeButton:active,
.largeButton:focus {
  outline: none;
}

.loadingGif {
  mask-image: url("/ripple.svg");
}

.textLink:hover {
  text-decoration: underline;
}

.locationIcon {
  mask-image: url(/location.svg);
  height: 14pt;
  width: 14pt;
  background-color: $textColor;
}

.shippingIcon {
  mask-image: url(/icons/shipping.svg);
  height: 14pt;
  width: 14pt;
  background-color: $textColor;
}

.whiteIcon {
  background-color: white !important;
}
