@import "../styles/variables.scss";

$filterWidth: 100%;

.filter_mimic {
  height: 30pt;
  // width: $filterWidth;
  border: 1pt solid black;
  border-radius: 2pt;
}

.filter_mimic {
  cursor: default;
  user-select: none;
}

.mimic_text {
  font-size: 12pt;
  line-height: 15pt;
  padding: 6pt 10pt;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter_content {
  max-width: $filterWidth;
  display: flex;
  //   justify-content: center;
  flex-wrap: wrap;
  padding: 10pt;
  padding-bottom: 20pt;
  margin-top: 10pt;
  user-select: none;
  position: absolute;
  z-index: $zindexFiltersSelect;
  background-color: white;
  box-shadow: $dropShadowDark;
  max-height: 485pt;
  overflow: scroll;
}

.hide_content {
  display: none;
}

.category_option {
  padding: 3pt 6pt;
  border-radius: 6pt;
  background-color: rgb(253, 241, 243);
  margin: 5pt;
}

.size_header {
  margin-top: 7pt;
  width: 100%;
}

.size_section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.size_option {
  padding: 3pt 9pt;
  border-radius: 100pt;
  margin: 5pt 3pt;
}

.unselected {
  background-color: #f0f0f0;
  border: solid 1px white;
}

.selected {
  background-color: rgb(253, 241, 243);
  border: solid 1px rgb(238, 203, 209);
}

.select_component {
  width: 100%;
  position: relative;
}

@media screen and (max-width: 650pt) {
  .filter_content {
    left: 0;
    max-width: 100%;
    width: 100%;
  }

  .select_component {
    position: inherit;
  }
}
