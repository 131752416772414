@import "../styles/variables.scss";

.filters {
  margin: auto;
  width: 100%;
  display: grid;
  grid-template-columns: $itemSize $itemSize $itemSize;
  justify-items: center;
  grid-gap: 16pt;
}

.filtersWidth {
  width: 746pt;
  padding-right: 0pt;
  padding-left: 0pt;
}

.filtersLabel {
  font-size: 12pt;
  margin-right: 10pt;
  padding-right: 5pt;
  height: 30pt;
  font-weight: 600;
}

.filtersSort {
  border: 1px solid black;
  padding: 5pt 10pt;
  font-size: $smallFontSize;
}

.filters select {
  width: 100%;
  border-radius: 2pt;
}

.num {
  font-size: 10pt;
  margin: 0pt 10pt;
}

.filter_gap {
  flex: 1 0 10pt;
}

@media screen and (max-width: 766pt) {
  .filtersWidth {
    padding-right: $sideMargin / 2;
    padding-left: $sideMargin / 2;
  }

  .filters {
    margin: auto;
    width: 100%;
    display: grid;
    grid-template-columns: calc((100% - 30pt) / 3) calc((100% - 30pt) / 3) calc(
        (100% - 30pt) / 3
      );
    justify-items: center;
    grid-gap: 15pt;
  }
}
