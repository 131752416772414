// Don't add any URLS to this file

@import "styles/variables.scss";
@import "styles/alignment.scss";
@import "styles/typography.scss";
@import "styles/buttons.scss";
@import "styles/forms.scss";

// There's a bug on windows where you can't have url() css rules on this file.

html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $softTextColor;
  background-color: $accentColor;
}

#root {
  background-color: $backgroundColor;
}

footer {
  bottom: 0px;
  left: 0px;
  position: fixed;
  width: 100%;
  height: 60pt;
  background-color: white;
  box-shadow: 10pt 10pt 30pt rgba(100, 100, 100, 0.3);
  display: grid;
  grid-template-columns: 25% 50% 25%;
}

main {
  background-color: white;
}

.noscroll {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.overlay {
  position: fixed;
  top: 0pt;
  left: 0pt;
  right: 0pt;
  bottom: 0pt;
  background-color: rgba(0, 0, 0, 0.8);
}

.objectContainer {
  background-color: #f2f2f2;
}

.loadingGif {
  height: 30pt;
  width: 30pt;
  padding-top: 4pt;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  left: -100%;
  top: 35pt;
  font-size: $defaultFontSize;
  font-weight: 500;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: $zindexTooltip;
}

.headerTooltip .tooltiptext {
  left: 50%;
}

a:active .headerTooltip .tooltiptext {
  top: 33pt;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltip-bottom,
.tooltip .tooltip-bottom-extra {
  top: calc(100% + 4pt);
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltip-bottom-extra {
  top: calc(100% + 16pt);
}

.tooltip .tooltip-top {
  top: -30pt;
  left: 50%;
  margin-left: -60px;
}

.loadingGif {
  background-color: $softTextColor;
  width: 32px;
  height: 32px;
  // -webkit-mask-image: url(/ripple.svg);
  // mask-image: url(/ripple.svg);
}

.whiteLoadingGif {
  background-color: white;
}

.activeAddSelect {
  color: $textColor !important;
}

// Debug CSS Rule

// * {
//   background: #000 !important;
//   color: #0f0 !important;
//   outline: solid #f00 1px !important;
// }
