@import "../styles/variables.scss";

body.unscrollable {
  overflow: hidden;
}

.modalContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  overflow: auto;
  z-index: $zindexModalBackground;
  animation: fadein 0.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modalBackdrop {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: $zindexModalBackground;
  overflow: auto;
}

.modalForeground {
  position: absolute;
  background-color: white;
  top: 10%;
  left: calc(50% - 250pt);
  width: 500pt;
  z-index: $zindexModalContent;
  padding: 20pt;
  padding-bottom: 5pt;
  // margin-bottom: 50pt;
}

.modalTitle {
  margin-bottom: 12pt;
}

.googleLoginContainer {
  text-align: center;
}

.modalTextField {
  display: inline-block !important;
  border: none;
  font-size: 12pt;
  border-bottom: 1pt solid #bbb;
}

.modalTextArea {
  border: 1px solid black;
  padding: 10pt;
  width: 400pt;
  height: 100pt;
  max-width: 100%;
  border-radius: 2pt;
  font-size: 14pt;
}

.modalTextField:focus {
  outline: none;
  border-bottom: 1pt solid #333;
}

.textFieldStatusLabel {
  margin-top: 20pt;
  margin-bottom: 40pt;
  position: absolute;
  left: 0%;
  width: 100%;
  max-width: 100% !important;
}

.modalContainer p,
.modalContainer h1,
.modalContainer h2,
.modalContainer h3,
.modalContainer h4,
.modalContainer h5 {
  max-width: 400pt;
  margin-left: auto;
  margin-right: auto;
}

.modalContainer p {
  font-size: 14pt;
  line-height: 20pt;
}

.rippleStatusContainer {
  margin-top: 14pt;
  margin-bottom: 33.5pt;
  left: 0%;
  width: 100%;
  max-width: 100% !important;
  position: absolute;
}

.fillerDiv {
  height: 72pt;
}

.stepsList {
  margin-top: 16pt;
  margin-bottom: 24pt;
  max-width: 375pt;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.stepsList li {
  margin-bottom: 8pt;
}

.itemIcon.modalCloseIcon {
  background-color: white;
  position: relative;
  top: -55pt;
  float: right;
  right: -20pt;
  mask-image: url(/close.svg);
}

.scrollableModalContent {
  overflow: scroll;
  height: 400pt;
}

@media only screen and (max-width: 600px) {
  .modalForeground {
    top: 10%;
    left: 0%;
    width: 100%;
  }
}
